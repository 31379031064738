import React, { useState } from 'react'
import { useEffect } from 'react';

function Navbar() {

  // handle nav hide on scorll
  const [activeNav, setActiveNav] = useState(true)
  const [scrollPos, setScrollPos] = useState(window.scrollY)

  useEffect(()=>{
    const handelScorll = () => {
      setScrollPos(window.scrollY)
      setActiveNav(scrollPos > window.scrollY)
    }
    window.addEventListener('scroll', handelScorll)
    return ()=> window.removeEventListener('scroll', handelScorll)
  }, [scrollPos])

  return (
    <div
      className={`hidden sm:flex sm:fixed top-0 left-0 w-full bg-mainClr items-center justify-around h-[70px] transition duration-700 z-50 ${!activeNav && '-translate-y-[70px]'}`}
      >


      <div className='flex gap-2 lg:gap-4'>

      </div>

      <div className='flex gap-4'>

      </div>
    </div>
  )
}

export default Navbar
