import React from 'react'

function MobNav() {
  return (
    <div className='h-[70px] mb-4 flex sm:hidden justify-start items-center text-txtClr bg-mainClr'>

    </div>
  )
}

export default MobNav
