import React from 'react'
import MobNav from './MobNav'
import Navbar from './Navbar'

function Home() {
    return (
        <main id='home' className='overflow-hidden relative h-full w-full'>

            <MobNav/>

            <div className='layout-margin min-h-screen text-white'>

                <div
                    className='absolute -top-[30%] 2xl:-top-[45%] -left-16 2xl:-left-32 h-full w-[calc(100vw*1.25)] sm:w-[calc(100vw*1.2)] bg-mainClr rounded-[182px] -rotate-[23.26deg] -z-50'/>

                <Navbar/>

                <div className='min-h-[calc(100vh-30px)] flex flex-col sm:flex-row items-center sm:justify-center'>

                    {/* hero left pane */}
                    <div className='sm:w-1/2 flex flex-col justify-center items-start text-left'>
                        <h2>CardGifty App</h2>
                        <h4 className={'mt-4'}>Gift cards for occasions</h4>

                        <p className='w-3/4 mt-4'>Send personalized ecards for any occasion with Cardgifty - the
                            ultimate gift card maker! Choose from fun templates, add videos, stickers, and
                            invitations</p>

                        <p className='text-2xl font-bold mt-10 mb-4'>Download the app now!</p>

                        <div className='mt-8 mb-4'>

                            <a href={"https://apps.apple.com/us/app/cardgifty-birthday-wish-card/id1671443200"} target="_blank">
                                <img
                                    src="https://res.cloudinary.com/do1rqqi7v/image/upload/v1661779376/Projects/GRO%20shop/App_Store__iOS_-Badge-Alternative-Logo.wine_w2jxp3.png"
                                    alt="/" className='w-[150px] sm:w-[200px] h-12 sm:h-16 cursor-pointer'/>
                            </a>

                            <div className='mt-8 mb-4'>
                            <a href={"https://play.google.com/store/apps/details?id=com.zisantolunay.happybirthday"} target="_blank">
                                <img
                                    src="/google_play_store.png"
                                    alt="/" className='w-[150px] sm:w-[200px] h-12 sm:h-16 cursor-pointer'/>
                            </a>
                            </div>
                        </div>
                    </div>

                    {/* hero right pane */}
                    <div className='relative mt-12 w-1/2 sm:min-h-[calc(100vh-70px)]'>
                        <img
                            src="cardGifty-frame2.png"
                            alt="/"
                            className='absolute top-[10%] lg:top-[15%] xl:top-[10%] right-12 sm:right-40 lg:right-48 w-[140px] sm:w-[190px] xl:w-[300px] -rotate-[25.45deg]'/>
                        <img
                            src="cardGifty-frame.png"
                            alt="/"
                            className='absolute top-[20%] -right-12 sm:right-8 xl:right-0 w-[150px] sm:w-[200px] xl:w-[300px]'/>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Home
