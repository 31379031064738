import React from 'react'
import Home from '../components/Home'

function LandingScreen() {
    return (
        <div className='select-none'>
            <Home/>
        </div>
    )
}

export default LandingScreen
