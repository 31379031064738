import * as React from "react";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from 'react-query'
import CardScreen from "./screen/CardScreen";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import LandingScreen from "./screen/LandingScreen";

const firebaseConfig = {
    apiKey: "AIzaSyCmqgMuHLYXuptAA3EvYObR6CeS2_99WrI",
    authDomain: "cardgifty-e8612.firebaseapp.com",
    projectId: "cardgifty-e8612",
    storageBucket: "cardgifty-e8612.appspot.com",
    messagingSenderId: "997567483847",
    appId: "1:997567483847:web:103895f6034cd505e513e2",
    measurementId: "G-53RC1B1GVN"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

function App() {

    return (
        <div>
            <BrowserRouter>

                    <Routes>
                        <Route exact path="" element={<LandingScreen/>}/>
                        <Route exact path="/card/:id" element={<CardScreen/>}/>
                    </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;
