import * as React from "react";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import styles from "./CardScreen.module.css"
import HTMLFlipBook from "react-pageflip";
import {useParams} from "react-router";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../App";

const musicCodeBase = "https://scannables.scdn.co/uri/plain/png/1DB954/white/640/"

const CoverPage = React.forwardRef(({from, onPress}, ref) => {
    return (
        <div onClick={onPress} ref={ref} data-density="hard">
            <div className={styles.page}>
                <div className={styles.coverFrom}>From</div>
                <div className={styles.coverFromValue}>{from}</div>
                <div className={styles.coverTap}>Tap to Open</div>
            </div>
        </div>
    );
});

const VideoPage = memo(React.forwardRef(({videoSource, vidRef, musicCodeUri, musicSpotifyUrl, onLoad, onPress}, ref) => {
    console.log("Video render oluyor")

    return (

        <div ref={ref} onClick={onPress} data-density="hard">
            <div className={styles.contentPage}>
                <video ref={vidRef}
                       className={styles.videoPageVideo}
                       loop={true}
                       playsInline={true}
                    // onCanPlayThrough={onLoad}
                       muted={true}
                       webkit-playsinline="true"
                >
                    <source src={videoSource} type="video/mp4"/>
                </video>
                {musicCodeUri !== undefined && musicCodeUri !== null && <a className={styles.musicCodeA} href={musicSpotifyUrl} target="_blank"><img
                    src={musicCodeBase + musicCodeUri}
                    alt={"Spotify Music Code"}
                    className={styles.videoPageMusicCodeImage}
                /></a>}
            </div>
        </div>

    );
}));

const ImagePage = memo(React.forwardRef(({imageUri, musicCodeUri, musicSpotifyUrl, onPress}, ref) => {
    return (

        <div onClick={onPress} ref={ref} data-density="hard">
            <div className={styles.contentPage}>
                <img
                    className={styles.imagePageImage}
                    alt={"card image"}
                    src={imageUri}/>
                {musicCodeUri !== undefined && musicCodeUri !== null && <a className={styles.musicCodeA} href={musicSpotifyUrl} target="_blank"><img
                    src={musicCodeBase + musicCodeUri}
                    alt={"Spotify Music Code"}
                    className={styles.videoPageMusicCodeImage}
                /></a>}
            </div>
        </div>

    );
}));

const EndPage = React.forwardRef(({imageUri, onReplayClick}, ref) => {
    return (
        <div ref={ref} data-density="hard">
            <div className={styles.page}>
                <div className={styles.endText}>Send your lover a card</div>
                <div className={styles.endSubText}>Get the CardGifty App</div>
                <img
                    className={styles.endImage}
                    alt={"app store"}
                    onClick={() => window.open('https://apps.apple.com/us/app/cardgifty-ecards-for-events/id1671443200', '_blank', 'noreferrer')}
                    src={"/app-store.png"}/>
                <img
                    className={styles.endImage}
                    alt={"google play store"}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zisantolunay.happybirthday', '_blank', 'noreferrer')}
                    src={"/google_play_store.png"}/>
                <img
                    className={styles.replayImage}
                    alt={"replay"}
                    onClick={onReplayClick}
                    src={"/replay-icon.png"}/>
            </div>


        </div>
    );
});

const CardNotFoundPage = () => {
    return (

        <div className={styles.cardNotFoundPage}>
            <div className={styles.cardNotFoundText}>Card Not Exists <br/><br/>Don't be sad<br/>🥺</div>
            <div className={styles.cardNotFoundSubText}>Send a card below</div>
            <img
                className={styles.cardNotFoundImage}
                alt={"app store"}
                onClick={() => window.open('https://apps.apple.com/us/app/cardgifty-ecards-for-events/id1671443200', '_blank', 'noreferrer')}
                src={"/app-store.png"}/>
            <img
                className={styles.cardNotFoundImage}
                alt={"google play store"}
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zisantolunay.happybirthday', '_blank', 'noreferrer')}
                src={"/google_play_store.png"}/>
        </div>

    );
}

const Loading = ({show}) => {
    return (
        show === true &&
        <div className={styles.loading}>
            <img alt={"loading"} src={"/loader.svg"} className={styles.loadingLoader}/>
            <div className={styles.loadingText}>Please wait</div>
        </div>

    );
}

const EmptyPage = React.forwardRef((props, ref) => {
    return (
        <div className={styles.emptyPage} ref={ref} data-density="hard">

        </div>
    );
});

function CardScreen() {

    const {id} = useParams();
    console.log("id:", id)

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const [audio, setAudio] = useState();

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    const [loading, setLoading] = useState(true)
    const vidRef = useRef();
    const bookRef = useRef();
    const [card, setCard] = useState()

    const getCard = async () => {
        const cardRef = doc(db, "cards", id);
        const card = await getDoc(cardRef).catch((e) => console.log(e));
        if (card.exists()) {
            console.log("card:", card.data())
            const data = card.data();
            setCard({
                type: data.type,
                uri: data.uri,
                from: data.from,
                musicMp3Uri: data.musicMp3Uri,
                musicImageUri: data.musicImageUri,
                musicCodeUri: data.musicCodeUri,
                musicName: data.musicName,
                musicSpotifyUrl: data.musicSpotifyUrl
            })

            if (data.musicMp3Uri !== undefined && data.musicMp3Uri !== null) {
                const audio1 = new Audio(data.musicMp3Uri);
                audio1.loop = true
                audio1.load()
                setAudio(audio1)
            }
            setLoading(false)
        } else {
            console.log("card bulunamadı")
            setLoading(false)
        }
    }

    useEffect(() => {
        getCard().then();
    }, [])


    const playVideo = () => {
        if (vidRef.current === null) return
        vidRef.current.currentTime = 0;
        vidRef.current.play();
        console.log("Video started")
    }

    const playSound = () => {
        if (audio !== undefined) {
            if (audio.paused) {
                audio?.play()
            }
        }
    }

    const stopSound = () => {
        if (audio !== undefined) {
            audio?.pause()
        }
    }

    const onFlip = useCallback((e) => {
        const page = e.data;
        if (card.type === "IMAGE") {
            if (page === 1) {
                playSound()
            }
        } else {
            if (page === 1) {
                playVideo()
                playSound()
            }
        }

    }, [card]);

    const onLoad = () => {
        console.log("Video loaded")
        setLoading(false)
    }

    const getWidthHeightForNormal = () => {
        const maxWindowWidth = windowSize[0] - windowSize[0]/10
        const maxWindowHeight = windowSize[1] - windowSize[1]/10

        if(maxWindowWidth >= maxWindowHeight) {
            const x = maxWindowHeight / 6
            const canvasHeight = 6*x;
            const canvasWidth = 4*x;
            return {width: canvasWidth, height: canvasHeight}
        } else {
            const x = maxWindowWidth / 4
            const canvasHeight = 6*x;
            const canvasWidth = 4*x;


            if(canvasHeight >= maxWindowHeight) {
                const x = maxWindowHeight / 6
                const canvasHeight = 6*x;
                const canvasWidth = 4*x;
                return {width: canvasWidth, height: canvasHeight}
            }

            return {width: canvasWidth, height: canvasHeight}
        }
    }

    const getWidthHeightForMusic = () => {
        const maxWindowWidth = windowSize[0] - windowSize[0]/10
        const maxWindowHeight = windowSize[1] - windowSize[1]/10

        if(maxWindowWidth >= maxWindowHeight) {
            const x = maxWindowHeight / 7
            const canvasHeight = 6*x;
            const canvasWidth = 4*x;
            const spotifyHeight = x;
            const spotifyWidth = 4*x;
            return {width: canvasWidth, height: canvasHeight + spotifyHeight}
        } else {
            const x = maxWindowWidth / 4
            const canvasHeight = 6*x;
            const canvasWidth = 4*x;
            const spotifyHeight = x;
            const spotifyWidth = 4*x;

            if(canvasHeight + spotifyHeight >= maxWindowHeight) {
                const x = maxWindowHeight / 7
                const canvasHeight = 6*x;
                const canvasWidth = 4*x;
                const spotifyHeight = x;
                const spotifyWidth = 4*x;
                return {width: canvasWidth, height: canvasHeight + spotifyHeight}
            }

            return {width: canvasWidth, height: canvasHeight + spotifyHeight}
        }
    }

    const getWidthHeight = (card) => {
        if (card.musicMp3Uri === null || card.musicMp3Uri === undefined) {
            return getWidthHeightForNormal()
        } else {
            return getWidthHeightForMusic()
        }
    }

    return (
        <div className={styles.app} style={{
            height: windowSize[1],
            backgroundColor: 'rgba(255,170,0,0.05)',
            justifyContent: 'center',
            display: 'flex'
        }}>

            <div>
                {card && <HTMLFlipBook
                    ref={bookRef}
                    onFlip={onFlip}
                    width={getWidthHeight(card).width}
                    height={getWidthHeight(card).height}
                    showCover={true}
                    useMouseEvents={false}
                    drawShadow={false}>
                    <CoverPage from={card.from} onPress={() => {
                        playSound()
                        bookRef.current.pageFlip().flipNext()
                    }}/>

                    {card.type === "IMAGE" ? <ImagePage
                        imageUri={card.uri}
                        musicCodeUri={card.musicCodeUri}
                        musicSpotifyUrl={card.musicSpotifyUrl} onPress={() => {
                        bookRef.current.pageFlip().flipNext()
                    }}/> : <></>}

                    {card.type === "VIDEO" ? <VideoPage
                        videoSource={card.uri}
                        musicCodeUri={card.musicCodeUri}
                        musicSpotifyUrl={card.musicSpotifyUrl}
                        vidRef={vidRef}
                        onLoad={onLoad}
                        onPress={() => {
                            bookRef.current.pageFlip().flipNext()
                        }}/> : <></>}

                    <EndPage onReplayClick={() => bookRef.current.pageFlip().flip(0)}/>
                </HTMLFlipBook>}

                {(card === undefined && loading === false) && <CardNotFoundPage/>}

            </div>
            <Loading show={loading}/>
        </div>
    )
}

export default memo(CardScreen);
